<template>
  <div>
    <a-form :model="formModel" :label-col="{span:5}" :wrapper-col="{span:19}">
      <a-form-item label="iOS过审版本" name="iosCode">
        <a-input v-model:value="formModel.iosCode" />
      </a-form-item>
      <a-form-item label="安卓过审版本" name="andCode">
        <a-input v-model:value="formModel.andCode" />
      </a-form-item>
      <a-form-item label="小程序过审版本" name="wxCode">
        <a-input v-model:value="formModel.wxCode" />
      </a-form-item>
    </a-form>
    <div style="text-align: center;">
      <a-button type="primary" size="small" @click="handleConfirm">保存</a-button>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getAppVersionList, update } from "../api/version";
import { stringify } from "qs";
import { message } from "ant-design-vue";

export default {
  setup() {
    let vlist = [];
    const formModel = ref({
      iosCode: "",
      andCode: "",
      wxCode: ""
    });

    const handleConfirm = async () => {
      const res1 = await update(
        stringify({
          id: vlist.filter(el => el.type === "iOS")[0].id,
          standardVersionCode: formModel.value.iosCode
        })
      );
      const res2 = await update(
        stringify({
          id: vlist.filter(el => el.type === "Android")[0].id,
          standardVersionCode: formModel.value.andCode
        })
      );
      const res3 = await update(
        stringify({
          id: vlist.filter(el => el.type === "wechatApplet")[0].id,
          standardVersionCode: formModel.value.wxCode
        })
      );
      if (
        res1.msg == "根据id更新appVersion成功" &&
        res2.msg == "根据id更新appVersion成功" &&
        res3.msg == "根据id更新appVersion成功"
      ) {
        message.success("保存成功！");
      }
    };
    const loadList = async () => {
      const { data: res } = await getAppVersionList();
      vlist = res || [];
      vlist.forEach(el => {
        if (el.type === "iOS") {
          formModel.value.iosCode = el.standardVersionCode;
        } else if (el.type === "Android") {
          formModel.value.andCode = el.standardVersionCode;
        } else if (el.type === "wechatApplet") {
          formModel.value.wxCode = el.standardVersionCode;
        }
      });
    };

    onMounted(loadList);

    return {
      formModel,
      handleConfirm
    };
  }
};
</script>

<style lang='scss' scoped>
</style>
