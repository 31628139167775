import { request } from '../utils/axios'

export function getAppVersionList () {
  return request({
    url: '/wuan/admin/appVersion/list',
    method: 'get'
  })
}
export function update (p) {
  return request({
    url: '/wuan/admin/appVersion/update',
    method: 'post',
    data: p
  })
}
